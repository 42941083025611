import { template as template_40c93fa85edd4447a732a2f05eb05e85 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_40c93fa85edd4447a732a2f05eb05e85(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
