import { template as template_36148931eb8e45a2beaa0889f93d321b } from "@ember/template-compiler";
const FKText = template_36148931eb8e45a2beaa0889f93d321b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
