import { template as template_225da9fdc6be43b3a8588a2c5c5d90c8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_225da9fdc6be43b3a8588a2c5c5d90c8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
