import { template as template_ec3bd273a7084d47966b27dbbdbab425 } from "@ember/template-compiler";
const FKLabel = template_ec3bd273a7084d47966b27dbbdbab425(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
